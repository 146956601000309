<template>
  <cz-dialog
    :width="500"
    v-on="$listeners"
    v-bind="$attrs"
    :title="$t('customer.userManagement.inviteUserTitle')"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-form
        class="px-4"
        @submit.prevent="$emit('on-invite', form)"
        :disabled="loading"
      >
        <cz-form-field
          required
          :label="$t('customer.userManagement.userEmail')"
        >
          <validation-provider
            rules="required|email"
            :name="$t('auth.email')"
            v-slot="{ errors }"
          >
            <cz-input
              v-model="form.email"
              type="email"
              :error-messages="errors"
            />
            <cz-form-field required :label="$t('customer.userManagement.role')">
              <validation-provider
                rules="required"
                :name="$t('customer.userManagement.role')"
                v-slot="{ errors }"
              >
                <cz-autocomplete
                  v-model="form.role"
                  :items="filteredInvitationTypes"
                  :error-messages="errors"
                  :readonly="invitationRoleDisabled"
                ></cz-autocomplete>
              </validation-provider>
            </cz-form-field>
          </validation-provider>
        </cz-form-field>

        <div class="d-flex justify-center" style="gap: 15px">
          <cz-button
            color="primary"
            type="submit"
            :disabled="invalid"
            :loading="loading"
            :title="$t('customer.userManagement.inviteUser')"
            large
          />

          <div
            class="negative--text text-subtitle-2 font-weight-semibold"
            v-if="errorMessage"
          >
            {{ errorMessage }}
          </div>
        </div>
      </v-form>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import {
  CzDialog,
  CzFormField,
  CzInput,
  CzAutocomplete,
  CzButton
} from '@/components';
import InvitationType from '@/shared/types/InvitationType';
export default {
  name: 'InviteUserDialog',
  components: {
    CzDialog,
    CzFormField,
    CzInput,
    CzButton,
    CzAutocomplete
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * Allowed invitations for user to create
     * by default is *, means that the user can invite all
     * various roles.
     * It can also accept Array of invitation types and then we will allow
     * the user to create for these specific invitations only
     */
    allowedInvitationTypes: {
      type: String | Array,
      default: '*'
    },
    errorMessage: {
      type: String
    }
  },
  computed: {
    filteredInvitationTypes() {
      if (this.allowedInvitationTypes === '*') {
        return this.invitationTypes;
      } else {
        return this.invitationTypes.filter((item) => {
          return this.allowedInvitationTypes.includes(
            item.value.invitationType
          );
        });
      }
    }
  },
  created() {
    if (this.filteredInvitationTypes?.length === 1) {
      this.form.role = this.filteredInvitationTypes[0].value;
      this.invitationRoleDisabled = false;
    }
  },
  data() {
    return {
      form: {
        email: '',
        role: ''
      },
      invitationRoleDisabled: false,
      invitationTypes: [
        {
          text: this.$t('customer.userManagement.roles.vendor'),
          value: {
            invitationType: InvitationType.VendorAdmin
          }
        },
        {
          text: this.$t('customer.userManagement.roles.customerAdmin'),
          value: {
            invitationType: InvitationType.CustomerAdmin
          }
        },
        {
          text: this.$t('customer.userManagement.roles.customerPurchaser'),
          value: {
            invitationType: InvitationType.CustomerPurchaser
          }
        },
        {
          text: this.$t('customer.userManagement.roles.customerAuthorizer'),
          value: {
            invitationType: InvitationType.CustomerAuthorizer
          }
        },
        {
          text: this.$t('customer.userManagement.roles.customerObserver'),
          value: {
            invitationType: InvitationType.CustomerObserver
          }
        },
        {
          text: this.$t('customer.userManagement.roles.customerCatalogAdmin'),
          value: {
            invitationType: InvitationType.CustomerCatalogAdmin
          }
        },
        {
          text: this.$t(
            'customer.userManagement.roles.customerDigitalArchiveViewer'
          ),
          value: {
            invitationType: InvitationType.CustomerDigitalArchiveViewer
          }
        }
      ]
    };
  }
};
</script>

<style></style>
